import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useScrollPosition from "@react-hook/window-scroll";
import { HOME, CASE_STUDIES, SERVICES, CONTACT } from "../constants/routes";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const scrollY = useScrollPosition();

  return (
    <nav
      className={`navbar navbar-expand-lg ${scrollY > 100 ? "nav-scroll" : ""}`}
    >
      <div className="container-fluid">
        <NavLink className="logo" to={HOME}>
          Whitespace
        </NavLink>

        <button
          className="navbar-toggler open-toggler"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <button
            className="navbar-toggler close-toggler"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="icon-close">
              <i className="fas fa-times"></i>
            </span>
          </button>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={HOME}
                exact
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={CASE_STUDIES}
              >
                Case studies
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={SERVICES}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to={CONTACT}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
