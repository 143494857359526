import React from "react";
import { Link } from "react-router-dom";
import Image from "alt-imageloader";
import CaseStudyHeader from "../../components/CaseStudyHeader";
import { CASE_STUDIES } from "../../constants/routes";

const Supplier360 = () => {
  return (
    <div className="new-casestudy">
      <CaseStudyHeader
        className="bg-supplier"
        name="Supplier360"
        title="Supplier data management platform"
        description="Platform that will provide industry-leading risk indicators
        and analytics tools."
        image="/img/supplier/s360_header.png"
        mobileImage="/img/supplier/mobile/s360_header-sm.png"
      />

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">
                  Product design for the best assistant in standardizing all the
                  data
                </h4>
                <p className="mb-m">
                  Supplier data management platform custom built for the Latin
                  American market. A digital platform that will provide
                  industry-leading risk indicators and analytics tools. Supplier
                  360 collates and standardizes all the data on an ongoing
                  basis.
                </p>
                <h4 className="mb-s">Methodology</h4>
                <p>Market research, User testing.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Challenge</h4>
                <p className="mb-m">
                  The main challenge was moving a market based upon manual work
                  into a digital platform, discovering its complex processes,
                  automating them and delivering it in a user-friendly and
                  intuitive manner.
                </p>
                <h4 className="mb-s">Solution</h4>
                <p>
                  Automate manual processes when onboarding vendors and improve
                  cross-department communication within company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding text-white bg-supplier">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">
                  Portfolio risk through custom workflows
                </h4>
                <p className="mb-l">
                  Create custom Workflows which collect all required documents
                  and data from supply partners. Quickly identify key at-risk
                  suppliers on the Supplier 360 dashboard.
                </p>
              </div>
            </div>
            <div className="col-lg-6 image-offset-margin mobile-margin">
              <div className="border-img--supplier-dark">
                <Image
                  src="/img/supplier/s360_1.png"
                  alt="supplier360"
                  isImage
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="border-img--supplier-dark">
                <Image
                  src="/img/supplier/s360_2.png"
                  alt="supplier360"
                  isImage
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services bg-gray section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="lr-layout-container">
                <div className="lr-layout">
                  <div className="lr-layout-item">
                    <div className="lr-layout-item-inner">
                      <h4 className="mb-s">Screening and risk assessment</h4>
                      <p>
                        Investigate suppliers by checking reports and risk
                        factors which evaluate company’s financial health of
                        doing business with it and accurate vendor master with
                        full visibility into who they do business with.
                      </p>
                    </div>
                  </div>
                  <Image
                    src="/img/supplier/s360_3.png"
                    alt="supplier360"
                    isImage
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="header header-casestudy valign wave wave-light bg-ca header--next-casestudy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-white caption header-text">
                <h6 className="fw-600">Codeanywhere</h6>
                <h1>Code from anywhere</h1>
                <Link
                  className="button-next"
                  to={`${CASE_STUDIES}/codeanywere`}
                >
                  <span>Next case study</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-img-container header-img-container--center">
                <div
                  className="header-img header-img--center desktop-img"
                  style={{
                    backgroundImage: `url('/img/ca/ca_header.png')`,
                  }}
                ></div>
                <Image
                  className="mobile-img"
                  src="/img/ca/mobile/ca_header-sm.png"
                  alt="supplier360"
                  isImage
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Supplier360;
