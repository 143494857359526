import React from "react";

const Loading = ({ button }) => {
  return (
    <div className={`${button ? "loading--button" : "loading"}`}>
      <div className="text-center middle">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
