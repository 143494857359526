import React, { useState } from "react";
import emailjs from "emailjs-com";
import Loading from "./Loading";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const onFormChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          setSuccess(true);
          setMessage("Message sent successfully.");
        },
        (error) => {
          setMessage("There was an error. Please try again.");
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="offset-lg-3 col-lg-6">
      <form className="form" onSubmit={submit}>
        <div className="messages"></div>
        <div className="controls">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <input
                  id="form_name"
                  type="text"
                  name="from_name"
                  placeholder="Name *"
                  required="required"
                  data-error="Firstname is required."
                  value={formData["from_name"]}
                  onChange={onFormChange}
                />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input
                  id="form_email"
                  type="email"
                  name="from_email"
                  placeholder="Email *"
                  required
                  data-error="Valid email is required."
                  value={formData["from_email"]}
                  onChange={onFormChange}
                />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  id="form_subject"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData["subject"]}
                  onChange={onFormChange}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <textarea
                  id="form_message"
                  name="message"
                  placeholder="Your Message *"
                  rows="4"
                  required
                  data-error="Your message is required."
                  value={formData["message"]}
                  onChange={onFormChange}
                ></textarea>
                <div className="help-block with-errors"></div>
              </div>
            </div>

            <div className="col-md-12 text-center">
              <button
                type="submit"
                className={`new-button ${
                  success ? "new-button--disabled" : ""
                }`}
              >
                <span>Send Message</span>
                {loading && <Loading button />}
              </button>
              <div className="mt-15">
                <p>{message}</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
