import React from "react";
import { Link } from "react-router-dom";
import Image from "alt-imageloader";
import CaseStudyHeader from "../../components/CaseStudyHeader";
import { CASE_STUDIES } from "../../constants/routes";

const Customer = () => {
  return (
    <div className="new-casestudy">
      <CaseStudyHeader
        className="bg-customer"
        name="CIAL360"
        title="Credit and risk decision making"
        description="A digital platform that will provide easy access to
            comprehensive company information to assist in credit and risk
            decision making."
        image="/img/customer/cial_header.png"
        mobileImage="/img/customer/mobile/cial_header-sm.png"
      />

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">
                  Product design for the best assistant in making risk and
                  credit decisions
                </h4>
                <p>
                  CIAL360 is a digital platform that provides easy access to
                  comprehensive company information to assist in credit and risk
                  decision making. It helps companies understand their
                  customers’ business profiles, whether to make decisions on
                  risks or provide an ideal credit line to grant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services bg-gray section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Design sprint and user testing </h4>
                <p className="mb-l">
                  We started by defining main goals and product challenges
                  together with stakeholders in creative atmosphere. With quick
                  but concrete outcome, prototypes were ready for user-testing.
                </p>
              </div>
              <Image
                isImage
                className="desktop-img"
                src="/img/customer/cial_design-sprint.png"
                alt="customer360"
              />
              <Image
                isImage
                className="mobile-img"
                src="/img/customer/mobile/cial_design-sprint-sm.png"
                alt="customer360"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Challenge</h4>
                <p className="mb-m">
                  A lot of finance teams still manage complex operations and
                  data in antiquated manual ways. The biggest challenge was to
                  cover those processes in a modern but familiar way and
                  environment.
                </p>
                <h4 className="mb-s">Solution</h4>
                <p>
                  Make credit decisions through faster sales cycle with all
                  stakeholders involved on one platform. Collaborate efficiently
                  so everyone can track credit decision processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding no-padding-top-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-text">
                <h4 className="mb-s">Explore companies</h4>
                <p className="mb-l">
                  Find companies, add them to custom-made groups, monitor them
                  and run actions on top of companies or entire groups such as
                  ordering reports or requesting a decision. Easy overview of
                  all saved searches and their dynamic changes.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mobile-margin">
              <div className="border-img--customer-light">
                <Image
                  isImage
                  src="/img/customer/cial_dashboard.png"
                  alt="customer360"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="border-img--customer-light">
                <Image
                  isImage
                  src="/img/customer/cial_search.png"
                  alt="customer360"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services bg-customer-light section-padding text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="lr-layout-container">
                <div className="lr-layout mb-l">
                  <div className="lr-layout-item">
                    <div className="lr-layout-item-inner">
                      <h4 className="mb-s">Request decisions</h4>
                      <p>
                        Approve or reject deals according to custom credit
                        policies and collaborate efficiently with all the
                        stakeholders involved so they can track the credit
                        decision process.
                      </p>
                    </div>
                  </div>
                  <Image
                    isImage
                    src="/img/customer/cial_request-decisions.png"
                    alt="customer360"
                  />
                </div>
                <div className="lr-layout lr-layout--left mb-l">
                  <Image
                    isImage
                    className="desktop-img"
                    src="/img/customer/cial_order-reports.png"
                    alt="customer360"
                  />
                  <div className="lr-layout-item">
                    <div className="lr-layout-item-inner">
                      <h4 className="mb-s">Order reports</h4>
                      <p>
                        Order reports and check ratings which evaluate company’s
                        financial health and the risk factors of doing business
                        with it and suggest a safe credit amount to offer
                        customers and suppliers.
                      </p>
                    </div>
                  </div>
                  <Image
                    isImage
                    className="mobile-img"
                    src="/img/customer/cial_order-reports.png"
                    alt="customer360"
                  />
                </div>
                <div>
                  <h4 className="mb-s">Monitor portfolio</h4>
                  <p className="mb-m text-box--small">
                    Add, edit or delete charts and monitor statistics of your
                    decisions. Have full overview of your portfolio in easier
                    and smarter way.
                  </p>
                  <Image
                    isImage
                    className="desktop-img"
                    src="/img/customer/cial_monitor-portfolio.png"
                    alt="customer360"
                  />
                  <Image
                    isImage
                    className="mobile-img"
                    src="/img/customer/mobile/cial_monitor-portfolio-sm.png"
                    alt="customer360"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Emphasis on accessible design</h4>
                <p className="mb-m">
                  With a traditional audience, accessible design was critical to
                  ensure users of all the roles could easily access the new
                  platform and have its own view depending on permission for
                  each role.
                </p>
                <h4 className="mb-s">
                  Aligning business needs with one platform
                </h4>
                <p>
                  The whole system needed to be realigned to the core business
                  goals. Identifying business vision was essential to
                  acknowledge user needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services bg-gray section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text">
                <h4 className="mb-s">
                  Solving problems: Decision request data amount
                </h4>
                <p className="mb-l">
                  Especially challenging was to transform a vast amount of data
                  into 5-step process where each step is dynamic and includes
                  various triggers that get user to the next step. The goal was
                  to reduce unnecessary noise as much as possible, while having
                  enough information to bring safe decisions with a great user
                  experience.
                </p>
              </div>
              <Image
                isImage
                className="desktop-img"
                src="/img/customer/cial_data-amount.png"
                alt="customer360"
              />
              <Image
                isImage
                className="mobile-img"
                src="/img/customer/mobile/cial_data-amount-sm.png"
                alt="customer360"
              />
            </div>
          </div>
        </div>
      </section>

      <header className="header header-casestudy valign wave wave-light bg-silk header--next-casestudy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-white caption header-text">
                <h6 className="fw-600">Silk</h6>
                <h1>Data source and process management</h1>
                <Link className="button-next" to={`${CASE_STUDIES}/silk`}>
                  <span>Next case study</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-img-container">
                <div
                  className="header-img desktop-img"
                  style={{
                    backgroundImage: `url('/img/silk/silk-header.png')`,
                  }}
                ></div>
                <Image
                  isImage
                  className="mobile-img"
                  src="/img/silk/mobile/silk_header-sm.png"
                  alt="customer360"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Customer;
