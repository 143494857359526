import React from "react";
import Image from "alt-imageloader";
import { Link } from "react-router-dom";

const CaseStudyItems = ({ caseStudies }) =>
  caseStudies.map((val, key) => {
    const content = (
      <>
        <div className={`item-img ${val.bgClass}`}>
          <div className="item-img-container">
            <Image src={val.image} animationDelay={0} isImage alt={val.title} />
          </div>
        </div>
        <div className="info-contain">
          <h5>{val.title}</h5>
          <p>{val.category}</p>
        </div>
      </>
    );

    return (
      <div className="col-lg-4 col-md-6 items" key={key}>
        {val.externalLink ? (
          <a href={val.externalLink} rel="noopener noreferrer" target="_blank">
            {content}
          </a>
        ) : (
          <Link to={val.link}>{content}</Link>
        )}
      </div>
    );
  });

export default CaseStudyItems;
