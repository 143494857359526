import React from "react";
import Image from "alt-imageloader";

const CaseStudyHeader = ({
  className,
  title,
  name,
  description,
  image,
  mobileImage,
  centerImage,
}) => {
  return (
    <header
      className={`header header-casestudy valign wave wave-light ${className}`}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="text-white caption header-text">
              <h6 className="fw-600">{name}</h6>
              <h1>{title}</h1>
              <h6>{description}</h6>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className={`header-img-container ${
                centerImage ? "header-img-container--center" : ""
              }`}
            >
              <Image
                className={`${
                  centerImage ? "alt-imageloader--center" : ""
                } desktop-img`}
                src={image}
              />
              <img className="mobile-img" src={mobileImage} alt={name} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default CaseStudyHeader;
