import React from "react";
import { Link } from "react-router-dom";
import Image from "alt-imageloader";
import CaseStudyHeader from "../../components/CaseStudyHeader";
import { CASE_STUDIES } from "../../constants/routes";

const Planin = () => {
  return (
    <div className="new-casestudy">
      <CaseStudyHeader
        className="bg-planin-light"
        name="Planin'"
        title="Social planning, quick and fun"
        description="An app that makes organising plans and your social life easy."
        image="/img/planin/planin_header.png"
        mobileImage="/img/planin/mobile/planin_heade-sm.png"
        centerImage
      />

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">
                  Mobile design for creating and sharing plans
                </h4>
                <p>
                  A mobile social planning app for simply creating shared plans
                  with friend. Users can see their own plans, create new ones
                  and invite their friends, accept or reject other’s requests
                  and comment on existing plans.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Challenge</h4>
                <p className="mb-m">
                  Design the UX of creating a new plan to be as easy and
                  straightforward as possible. Users have to be able to give
                  each plan a custom name, schedule the date and time, set the
                  location and also select friends to invite.
                </p>
                <h4 className="mb-s">Solution</h4>
                <p>
                  The main focus is for the process to be simple, smart and with
                  little or no manual typing involved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Step into the onboarding</h4>
                <p className="mb-l">
                  Get to know what it’s about and plan a great get together
                </p>
              </div>
              <div className="img-m">
                <div className="multiple-images">
                  <div className="multiple-images--item multiple-images--offset-1">
                    <Image
                      src="/img/planin/planin_onboarding-1.png"
                      alt="planin"
                      isImage
                    />
                  </div>
                  <div className="multiple-images--item multiple-images--offset-2">
                    <Image
                      src="/img/planin/planin_onboarding-2.png"
                      alt="planin"
                      isImage
                    />
                  </div>
                  <div className="multiple-images--item">
                    <Image
                      src="/img/planin/planin_onboarding-3.png"
                      alt="planin"
                      isImage
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bg-planin text-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Every plan has a story</h4>
                <p className="mb-l">
                  Create and share events for more efficient planning and keep
                  track of all plans you’ve created or have joines. Leave
                  comments and stay connected to other users.
                </p>
                <Image src="/img/planin/video.gif" alt="planin" isImage />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Confirm if you're coming!</h4>
                <p className="mb-l">
                  Stay up-to-date with changes to your events or invites from
                  other users. Confirm if you’re coming and join in on the fun.
                </p>
                <div className="multiple-images">
                  <div className="multiple-images--item multiple-images--offset-2">
                    <div className="border-img--planin-light">
                      <Image
                        src="/img/planin/planin_1.png"
                        alt="planin"
                        isImage
                      />
                    </div>
                  </div>
                  <div className="multiple-images--item">
                    <div className="border-img--planin-light">
                      <Image
                        src="/img/planin/planin_2.png"
                        alt="planin"
                        isImage
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="header header-casestudy valign wave wave-light bg-weekend header--next-casestudy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-white caption header-text">
                <h6 className="fw-600">Weekend</h6>
                <h1>Visionaries & individuals oriented festival</h1>
                <Link className="button-next" to={`${CASE_STUDIES}/weekend`}>
                  <span>Next case study</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-img-container header-img-container--center">
                <div
                  className="header-img header-img--center desktop-img"
                  style={{
                    backgroundImage: `url("/img/weekend/weekend_header.png")`,
                  }}
                ></div>
                <Image
                  className="mobile-img"
                  src="/img/weekend/mobile/weekend_heade-sm.png"
                  alt="planin"
                  isImage
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Planin;
