import { withRouter } from "react-router-dom";
import keyBy from "lodash/keyBy";
import { caseStudies } from "../data/casestudies";

const caseStudiesByPath = keyBy(caseStudies, "link");

const CaseStudy = ({ history }) => {
  const path = history.location.pathname;

  return caseStudiesByPath[path].container || null;
};

export default withRouter(CaseStudy);
