import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { init } from "emailjs-com";
import { HOME, CASE_STUDIES, SERVICES, CONTACT } from "./constants/routes";
import Landing from "./containers/Landing";
import CaseStudies from "./containers/CaseStudies";
import CaseStudy from "./containers/CaseStudy";
import Services from "./containers/Services";
import Contact from "./containers/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import ScrollToTop from "./utils/ScrollToTop";

init("user_PBCkEDtw3XbnBmRJYBD9W");

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Loading />
        <Header />
        <Switch>
          <Route path={HOME} exact>
            <Landing />
          </Route>
          <Route exact path={CASE_STUDIES}>
            <CaseStudies />
          </Route>
          <Route path={`${CASE_STUDIES}/:id`}>
            <CaseStudy />
          </Route>
          <Route path={SERVICES}>
            <Services />
          </Route>
          <Route path={CONTACT}>
            <Contact />
          </Route>
          <Route path="*">
            <div>404</div>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
