import { CASE_STUDIES } from "../constants/routes";
import Customer from "../containers/CaseStudies/Customer";
import Silk from "../containers/CaseStudies/Silk";
import Supplier360 from "../containers/CaseStudies/Supplier360";
import Codeanywhere from "../containers/CaseStudies/Codeanywhere";
import Planin from "../containers/CaseStudies/Planin";
import Weekend from "../containers/CaseStudies/Weekend";

export const caseStudyCategories = {
  PRODUCT: "Product",
  WEB: "Web",
};

export const caseStudies = [
  {
    title: "Customer360",
    category: caseStudyCategories.PRODUCT,
    image: "img/case_studies/C360.png",
    link: `${CASE_STUDIES}/customer360`,
    bgClass: "bg-customer",
    container: <Customer />,
  },
  {
    title: "Silk",
    category: caseStudyCategories.PRODUCT,
    image: "img/case_studies/Silk.png",
    link: `${CASE_STUDIES}/silk`,
    bgClass: "bg-silk",
    container: <Silk />,
  },
  {
    title: "Supplier360",
    category: caseStudyCategories.PRODUCT,
    image: "img/case_studies/Supplier.png",
    link: `${CASE_STUDIES}/supplier360`,
    bgClass: "bg-supplier",
    container: <Supplier360 />,
  },
  {
    title: "Codeanywhere",
    category: caseStudyCategories.PRODUCT,
    image: "img/case_studies/CA.png",
    link: `${CASE_STUDIES}/codeanywere`,
    bgClass: "bg-ca",
    container: <Codeanywhere />,
  },
  {
    title: "Planin",
    category: caseStudyCategories.PRODUCT,
    image: "img/case_studies/Planin.png",
    link: `${CASE_STUDIES}/planin`,
    bgClass: "bg-planin-light",
    container: <Planin />,
  },
  {
    title: "Weekend",
    category: caseStudyCategories.PRODUCT,
    image: "img/case_studies/Weekend.png",
    link: `${CASE_STUDIES}/weekend`,
    bgClass: "bg-weekend",
    container: <Weekend />,
  },
  {
    title: "Gulet Croatia",
    category: caseStudyCategories.WEB,
    image: "img/case_studies/Gulet.png",
    bgClass: "bg-gulet",
    externalLink: "https://www.gulet-croatia.com",
  },
  {
    title: "Sebastian Art",
    category: caseStudyCategories.WEB,
    bgClass: "bg-sebastian",
    image: "img/case_studies/Sebastianart.png",
    externalLink: "https://www.sebastianart.net",
  },
  {
    title: "Stjepko Mamic",
    category: caseStudyCategories.WEB,
    bgClass: "bg-stjepko",
    image: "img/case_studies/Stjepko.png",
    externalLink: "https://www.stjepkomamic.com",
  },
];
