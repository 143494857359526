import React from "react";
import { Link } from "react-router-dom";
import Image from "alt-imageloader";
import CaseStudyHeader from "../../components/CaseStudyHeader";
import { CASE_STUDIES } from "../../constants/routes";

const Codeanywhere = () => {
  return (
    <div className="new-casestudy">
      <CaseStudyHeader
        className="bg-ca"
        name="Codeanywhere"
        title="Code from anywhere"
        description="Mobile IDE which includes a text, code also a markup editor
        and SSH terminal console."
        image="/img/ca/ca_header.png"
        mobileImage="/img/ca/mobile/ca_header-sm.png"
        centerImage
      />

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Product design to code from anywhere</h4>
                <p className="mb-m">
                  Codeanywhere is a Mobile IDE which includes a text, code also
                  a markup editor and SSH terminal console, that has been
                  redesigned and reengineered to be light, fast, and
                  mobile-friendly. Connect to your VM or FTP, SFTP Servers,
                  Dropbox, Google Drive, OneDrive, Amazon S3, Github or
                  Bitbucket account and easily manipulate your resources,
                  folders or files just with the swipe of your finger.
                </p>
                <h4 className="mb-s">Challenge</h4>
                <p className="mb-m">
                  The big challenge was turning an advanced cloud editor that
                  was built as a desktop-first experience into a mobile-friendly
                  app by redesigning and reengineering it from the ground up to
                  be light, fast and user-friendly.
                </p>
                <h4 className="mb-s">Solution</h4>
                <p>
                  Detect what are the common use cases when users are coding
                  from their phone and emphasize that content first.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Code and deploy easily</h4>
                <p className="mb-m">
                  Organize and store files how you want them. Codeanywhere has
                  integrated clients for connecting to FTP, SFTP, FTPS, Dropbox,
                  or Google Drive so you can code and deploy without opening up
                  multiple programs.
                </p>
                <Image isImage src="/img/ca/ca_1.png" alt="codeanywhere" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="lr-layout-container">
                <div className="lr-layout">
                  <div className="lr-layout-item">
                    <div className="lr-layout-item-inner">
                      <h4 className="mb-s">Connection Wizard & Editor</h4>
                      <p>
                        Easily setup customized development environment in
                        Javascript, PHP, HTML, or 72 other languages or use
                        pre-made development environments. Codeanywhere bends to
                        your will, enabling you to customize it to fit your
                        development style and needs.
                      </p>
                    </div>
                  </div>
                  <Image
                    isImage
                    className="border-img--ca"
                    src="/img/ca/ca_2.png"
                    alt="codeanywhere"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Manage from only one place</h4>
                <p className="mb-l">
                  The system is made to be accessible in seconds. Set up
                  environments for your projects, manage everything smoothly
                  from one place and focus on what matters-coding.
                </p>
              </div>
              <div className="img-m border-img--ca">
                <Image isImage src="/img/ca/ca_3.png" alt="codeanywhere" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="header header-casestudy valign wave wave-light bg-planin-light header--next-casestudy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-white caption header-text">
                <h6 className="fw-600">Planin'</h6>
                <h1>Social planning, quick and fun</h1>
                <Link className="button-next" to={`${CASE_STUDIES}/planin`}>
                  <span>Next case study</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-img-container header-img-container--center">
                <div
                  className="header-img header-img--center desktop-img"
                  style={{
                    backgroundImage: `url("/img/planin/planin_header.png")`,
                  }}
                ></div>
                <Image
                  isImage
                  className="mobile-img"
                  src="/img/planin/mobile/planin_heade-sm.png"
                  alt="codeanywhere"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Codeanywhere;
