import React from "react";
import { Link } from "react-router-dom";
import Image from "alt-imageloader";
import CaseStudyHeader from "../../components/CaseStudyHeader";
import { CASE_STUDIES } from "../../constants/routes";

const Silk = () => {
  return (
    <div className="new-casestudy">
      <CaseStudyHeader
        className="bg-silk"
        name="Silk"
        title="Data source and process management"
        description="Data source and process management system that provides
        overvire of various processes used to collect and transform
        bulk data."
        image="/img/silk/silk-header.png"
        mobileImage="/img/silk/mobile/silk_header-sm.png"
      />

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Product design</h4>
                <p>
                  Silk is a data source and process management system. It
                  provides an overview of various processes used to collect and
                  transform bulk data. These are used for, among other things,
                  company credit and risk decision making. Processes can be
                  scheduled, listed and both their overall and in-depth status
                  is made visible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bottom-hanging-img-section text-white bg-silk--dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Challenge</h4>
                <p className="mb-m">
                  Make one managment system that can be used by people of very
                  differing backgrounds.
                </p>
                <h4 className="mb-s">Solution</h4>
                <p className="mb-l">
                  Support multiple use-cases with large amounts of data
                  displaying a compact summary of an amount of data that would
                  otherwise be too unwieldy to manage.
                </p>
              </div>
            </div>
            <div className="col-lg-6 image-offset-margin mobile-margin">
              <div className="border-img--silk">
                <Image src="/img/silk/silk_1.png" alt="silk" isImage />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="border-img--silk">
                <Image src="/img/silk/silk_2.png" alt="silk" isImage />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bottom-hanging-img-container">
        <div className="bottom-hanging-img-background bg-silk--dark"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="border-img--silk bottom-hanging-img">
                <Image src="/img/silk/silk_3.png" alt="silk" isImage />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Manipulate processes manually</h4>
                <p className="mb-m">
                  The scheduling view allows users to manually manipulate a
                  process state: starting and stopping a process or scheduling
                  it to run periodically at certain points in time.
                </p>
                <h4 className="mb-s">Take care of the system’s health</h4>
                <p className="mb-l">
                  A user can visit the grid view to quickly surmise the overall
                  health of the system - too much red is a sign of alarm. At the
                  same time, experienced developers can dive deeper into a
                  failing process' details to retrieve relevant and actionable
                  information.
                </p>
              </div>
              <div className="img-m">
                <Image src="/img/silk/silk_4.png" alt="silk" isImage />
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="header header-casestudy valign wave wave-light bg-supplier header--next-casestudy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-white caption header-text">
                <h6 className="fw-600">Supplier360</h6>
                <h1>Supplier data management platform</h1>
                <Link
                  className="button-next"
                  to={`${CASE_STUDIES}/supplier360`}
                >
                  <span>Next case study</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-img-container">
                <div
                  className="header-img desktop-img"
                  style={{
                    backgroundImage: `url("/img/supplier/s360_header.png")`,
                  }}
                ></div>
                <Image
                  className="mobile-img"
                  src="/img/supplier/mobile/s360_header-sm.png"
                  alt="silk"
                  isImage
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Silk;
