import React, { useState } from "react";
import { caseStudies, caseStudyCategories } from "../data/casestudies";
import CaseStudyItems from "../components/CaseStudyItems";

const CaseStudies = () => {
  const [categoryFilter, setCategoryFilter] = useState();

  const caseStudyItems = caseStudies.filter((val) =>
    categoryFilter ? categoryFilter === val.category : true
  );

  return (
    <div>
      <header className="header header-small valign wave wave-light">
        <div className="container">
          <div className="row">
            <div className="full-width text-center caption">
              <h1>Case studies</h1>
              <h5>
                Here is some of the work our team has designed and built for the
                clients that have trusted us.
              </h5>
            </div>
          </div>
        </div>
      </header>

      <section className="works section-padding bg-gray gallery">
        <div className="container">
          <div className="row">
            <div className="filtering text-center col-sm-12">
              <div className="filter">
                <span
                  className={!categoryFilter ? "active" : ""}
                  onClick={() => setCategoryFilter(null)}
                >
                  All
                </span>
                <span
                  className={
                    categoryFilter === caseStudyCategories.PRODUCT
                      ? "active"
                      : ""
                  }
                  onClick={() => setCategoryFilter("Product")}
                >
                  Product
                </span>
                <span
                  className={
                    categoryFilter === caseStudyCategories.WEB ? "active" : ""
                  }
                  onClick={() => setCategoryFilter("Web")}
                >
                  Web
                </span>
              </div>
            </div>

            <CaseStudyItems caseStudies={caseStudyItems} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaseStudies;
