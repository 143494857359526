import React from "react";
import { Link } from "react-router-dom";
import { CASE_STUDIES } from "../constants/routes";
import { caseStudies } from "../data/casestudies";
import CaseStudyItems from "../components/CaseStudyItems";
import ContactForm from "../components/ContactForm";

const Landing = () => {
  return (
    <div>
      <header className="header valign bg-img wave wave-light bg-yellow">
        <div className="container">
          <div className="row">
            <div className="full-width text-center caption">
              <h5 className="cd-headline clip">
                <span className="blc">We are</span>{" "}
                <span className="cd-words-wrapper">
                  <b className="is-visible">Thinkers</b>
                  <b>Doers</b>
                  <b>Designers</b>
                  <b>Developers</b>
                </span>
              </h5>
              <h1>We create things</h1>
            </div>
          </div>
        </div>
      </header>

      <section className="works section-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>Case studies</h4>
            </div>

            <CaseStudyItems caseStudies={caseStudies} />
          </div>
        </div>

        <div className="col-md-12 text-center mt-100">
          <Link to={CASE_STUDIES} className="new-button new-button--white">
            <span>
              See all case studies<i className="fas fa-arrow-right"></i>
            </span>
          </Link>
        </div>
      </section>

      <section className="services section-padding bg-dark">
        <div className="container">
          <div className="row">
            <div className="section-head text-left text-white mb-30 offset-lg-1 col-lg-10">
              <h4>Our services</h4>
              <p className="text-white">
                We work with you to design beautiful experiences and build
                efficient & scalable solutions.
              </p>
              <h5>Branding</h5>
              <h5>Research & strategy</h5>
              <h5>Design</h5>
              <h5>Development</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>Talk to us</h4>
            </div>
            <ContactForm />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
