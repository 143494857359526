import React from "react";
import { Link } from "react-router-dom";
import Image from "alt-imageloader";
import CaseStudyHeader from "../../components/CaseStudyHeader";
import { CASE_STUDIES } from "../../constants/routes";

const Weekend = () => {
  return (
    <div className="new-casestudy">
      <CaseStudyHeader
        className="bg-weekend"
        name="Weekend"
        title="Visionaries & individuals oriented festival"
        description="This three-day festival gathers new visionaries and
        individuals oriented towards the future."
        image="/img/weekend/weekend_header.png"
        mobileImage="/img/weekend/mobile/weekend_heade-sm.png"
        centerImage
      />

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">
                  Mobile design for festival schedules and networking
                </h4>
                <p className="mb-m">
                  Mobile app for festival that gathers new visionaries and
                  individuals oriented towards the future.
                </p>
                <h4 className="mb-s">Challenge</h4>
                <p className="mb-m">
                  Since for attendees this mobile app is primary source of
                  information, the challenge was except usual every-year content
                  (speakers, schedules, stages etc.) to create networking
                  feature between attendees.
                </p>
                <h4 className="mb-s">Solution</h4>
                <p>
                  Users see people with who they have matches based on their
                  mutual interests. When a user sees the match he or she would
                  like to network with, the next step would be to propose a
                  meeting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">
                  See what's next and don’t miss the party
                </h4>
                <p className="mb-l">
                  Dont' miss the schedule, save your favorite events, and track
                  what is happening hour by hour. For every event walk with us
                  through the map to find out where is each stage, includig top
                  up point and party zone.
                </p>
              </div>
              <div className="img-m">
                <div className="multiple-images">
                  <div className="multiple-images--item multiple-images--offset-1">
                    <div className="border-img--weekend">
                      <Image
                        src="/img/weekend/weekend_1.png"
                        alt="weekend"
                        isImage
                      />
                    </div>
                  </div>
                  <div className="multiple-images--item multiple-images--offset-2">
                    <div className="border-img--weekend">
                      <Image
                        src="/img/weekend/weekend_2.png"
                        alt="weekend"
                        isImage
                      />
                    </div>
                  </div>
                  <div className="multiple-images--item">
                    <div className="border-img--weekend">
                      <Image
                        src="/img/weekend/weekend_3.png"
                        alt="weekend"
                        isImage
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding bg-weekend text-white bottom-hanging-img-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-text section-text--center">
                <h4 className="mb-s">Problem solving: Networking feature</h4>
                <p className="mb-l">
                  Matching is the key. Networking feature is in a similar format
                  to dating app like Tinder, they have matches based on their
                  mutual interests. When a user sees the match he or she would
                  like to network with, the next step would be to propose a
                  meeting. The other person is notified of the connection
                  request. Upon accepting the invitation, they may instant
                  message each other and agree on details of the meeting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bottom-hanging-img-container bottom-hanging-img-container--no-padding">
        <div className="bottom-hanging-img-background bottom-hanging-img-background--2 bg-weekend"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="multiple-images bottom-hanging-img">
                <div className="multiple-images--item multiple-images--offset-2">
                  <div className="border-img--weekend">
                    <Image
                      src="/img/weekend/weekend_4.png"
                      alt="weekend"
                      isImage
                    />
                  </div>
                </div>
                <div className="multiple-images--item">
                  <div className="border-img--weekend">
                    <Image
                      src="/img/weekend/weekend_5.png"
                      alt="weekend"
                      isImage
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="lr-layout-container">
                <div className="lr-layout">
                  <div className="lr-layout-item">
                    <div className="lr-layout-item-inner">
                      <h4 className="mb-s">Keep track of what’s next</h4>
                      <p>
                        Attendees can easily keep track of sessions, speakers,
                        and see details about sesions and speaker profiles
                      </p>
                    </div>
                  </div>
                  <Image
                    className="border-img--weekend"
                    src="/img/weekend/weekend_6.png"
                    alt="weekend"
                    isImage
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="header header-casestudy valign wave wave-light bg-customer header--next-casestudy">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-white caption header-text">
                <h6 className="fw-600">CIAL360</h6>
                <h1>Credit and risk decision making</h1>
                <Link
                  className="button-next"
                  to={`${CASE_STUDIES}/customer360`}
                >
                  <span>Next case study</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-img-container">
                <div
                  className="header-img desktop-img"
                  style={{
                    backgroundImage: `url('/img/customer/cial_header.png')`,
                  }}
                ></div>
                <Image
                  className="mobile-img"
                  src="/img/customer/mobile/cial_header-sm.png"
                  alt="weekend"
                  isImage
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Weekend;
