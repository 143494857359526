import React from "react";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <>
      <header className="header header-small valign wave wave-light">
        <div className="container">
          <div className="row">
            <div className="full-width text-center caption">
              <h1>Let's work together!</h1>
            </div>
          </div>
        </div>
      </header>
      <section className="contact contact-single mb-100">
        <div className="container">
          <div className="row">
            <ContactForm />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
