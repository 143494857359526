import React from "react";
import ContactForm from "../components/ContactForm";

const Services = () => {
  return (
    <>
      <header className="header header-small valign wave wave-light">
        <div className="container">
          <div className="row">
            <div className="full-width text-center caption">
              <h1>Our services</h1>
              <h5>
                We work with you to design beautiful experiences and build
                efficient & scalable solutions.
              </h5>
            </div>
          </div>
        </div>
      </header>

      <section
        className="services section-padding bg-gray"
        data-scroll-index="2"
      >
        <div className="container services-container">
          <div className="row">
            <div className="section-head text-left mb-30 col-sm-12 col-lg-6">
              <h3 className="mb-20">Branding</h3>
              <p>
                We help brands define who they are, who they want to be and how
                they want others to see them. Identifying needs, we build a
                strong memorable identity design.
              </p>

              <h3 className="mb-20">Research & strategy</h3>
              <p>
                Through a deep understanding of the audience and market, our
                experts will define strategic approach, test and deliver the
                best solutions of roadmap to success.
              </p>
              <h3 className="mb-20">Design</h3>
              <p>
                We enjoy designing digital experiences integrated with
                technology that drive business grow and attracts and engages
                users.
              </p>
              <h3 className="mb-20">Development</h3>
              <p>
                Our developers build high performance digital experiences and
                products that are secure, scalable, powerful and connect with
                people.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>Talk to us</h4>
            </div>
            <ContactForm />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
